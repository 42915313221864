import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
// @mui
import { Button, Container, Stack, Typography } from "@mui/material";
// components
import Iconify from "../components/iconify";
// mock
// import FileManager from "../sections/@dashboard/files/FileManager";
import { setDialog } from "../features/dialog/dialogSlice";
import FileDataTable from "../sections/@dashboard/files/FileDataTable/FileDataTable";
import DisplayFolderStructure from "../sections/@dashboard/files/DisplayFolderStructure";
import { useGetFileDatasQuery } from "../features/fileData/fileDataApiSlice";
import Loading from "../components/spinners/Loading";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const dev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export default function FilesPage() {
  const [showUpload, setShowUpload] = useState(true);
  const { data: fileDatas, isLoading } = useGetFileDatasQuery();

  const dispatch = useDispatch();

  const toggleUpload = () => {
    dispatch(
      setDialog({
        title: "Datei hochladen",
        content: "FileUpload",
      })
    );
    setShowUpload(!showUpload);
  };

  const handleOpenNewFileDataDialog = () => {
    dispatch(
      setDialog({
        title: "Neues Dokument erstellen",
        type: "CREATE",
        content: "create-filedata",
      })
    );
  };

  const handleOpenFileDialog = (fileData, action) => {
    if (action === "RELEASE") {
      // Set action to SHOW so file will only be shown.
      dispatch(
        setDialog({
          title: "Dokument freigeben",
          type: "CREATE",
          content: "file-release",
          data: { fileData, action: "SHOW" },
        })
      );
      return null;
    }
    if (action === "DELETE") {
      // Set action to SHOW so file will only be shown.
      dispatch(
        setDialog({
          title: "Dokument löschen",
          type: "DELETE",
          content: "file-delete",
          data: { fileData, action },
        })
      );
      return null;
    }
    if (action === "create-proof") {
      dispatch(
        setDialog({
          title: `Nachweis erzeugen`,
          type: "CREATE",
          content: action,
          data: { fileData, action },
        })
      );
      return null;
    }
    dispatch(
      setDialog({
        title: "Datei anzeigen",
        type: "CREATE",
        content: "file-show",
        data: { fileData, action },
      })
    );
  };

  const handleOpenAssignFileDialog = (fileData) => {
    dispatch(
      setDialog({
        title: "Dokument zuweisen",
        type: "ASSIGN",
        content: "assign-file",
        data: { fileData },
      })
    );
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title> QM-Dokumente | Mojave Med QM </title>
      </Helmet>

      <Container maxWidth>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            QM-Dokumente
          </Typography>
          {dev && (
            <Button
              onClick={toggleUpload}
              variant="contained"
              sx={{ minWidth: 300 }}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Dokument hochladen
            </Button>
          )}
          <Button
            onClick={handleOpenNewFileDataDialog}
            variant="contained"
            sx={{ minWidth: 300 }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Neues Dokument erstellen
          </Button>
        </Stack>

        <DisplayFolderStructure
          handleOpenFileDialog={handleOpenFileDialog}
          handleOpenAssignFileDialog={handleOpenAssignFileDialog}
        />

        {fileDatas ? (
          <FileDataTable
            handleOpenFileDialog={handleOpenFileDialog}
            handleOpenAssignFileDialog={handleOpenAssignFileDialog}
            fileDatas={fileDatas}
          />
        ) : (
          <h1>Noch keine Dokumenteneinträge</h1>
        )}
      </Container>
    </>
  );
}
