import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Divider,
} from "@mui/material";

import { useAddNewFBMutation } from "../../../../features/entry/entryApiSlice";
import HorizontalNonLinearStepper from "./HorizantalNonLinearStepper";
import Iconify from "../../../../components/iconify";
import FormRisk from "../Forms/FormRisk";
import MySlideSnackbar from "../../../../components/snackbar/MySlideSnackbar";

export default function RiskDialog({ handleClose, riskDetails, reset }) {
  const [addNewFB] = useAddNewFBMutation();
  const dispatch = useDispatch();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [confirm, setConfirm] = useState(false);

  let oldData = {
    incidentType: riskDetails.incidentType,
    classification: riskDetails.classification,
    frequency: (() => {
      switch (riskDetails.frequency) {
        case "erstmalig":
          return "gering";
        case "selten":
          return "mittel";
        case "häufig":
          return "hoch";
        default:
          return riskDetails.frequency;
      }
    })(),
    title: riskDetails.title,
    description: riskDetails.description,
  };
  console.log("oldData", oldData);

  const { handleSubmit, control, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      ...oldData,
      kontrolle: {
        date: "1_monat",
        individuellesDatum: null,
      },
    },
  });

  const typeOfEntry = watch("incidentType");
  const zeitraumval = watch("kontrolle.date");
  const individuellesDatum = watch("kontrolle.individuellesDatum");

  useEffect(() => {
    if (zeitraumval && (zeitraumval !== "individuell" || individuellesDatum)) {
      setConfirm(true);
    } else {
      setConfirm(false);
    }
  }, [zeitraumval, individuellesDatum]);

  const onSubmit = async (data) => {
    try {
      const kontrolle = {
        date: "",
      };

      if (data.kontrolle.date === "1_monat") {
        const today = new Date();
        const nextMonth = new Date(today);
        nextMonth.setMonth(today.getMonth() + 1);
        kontrolle.date = nextMonth;
      } else if (data.kontrolle.date === "3_monate") {
        const today = new Date();
        const nextThreeMonths = new Date(today);
        nextThreeMonths.setMonth(today.getMonth() + 3);
        kontrolle.date = nextThreeMonths;
      } else if (data.kontrolle.date === "1_jahr") {
        const today = new Date();
        const nextYear = new Date(today);
        nextYear.setFullYear(today.getFullYear() + 1);
        kontrolle.date = nextYear;
      } else if (data.kontrolle.date === "individuell") {
        kontrolle.date = new Date(data.kontrolle.individuellesDatum);
      }

      const riskData = {
        incidentType: "risiko",
        title: data.title,
        description: data.description,
        status: data.status,
        classification: data.classification,
        frequency: data.frequency,
        measure: data.measure,
        responsibleUser: data.responsibleUser,
        oldIncidentResolved: { oldId: riskDetails._id },
      };
      let result = await addNewFB({ ...riskData, kontrolle });
      if (result) {
        handleClose("REFETCH");
        setMsg("Sie haben ein neues Risiko erstellt!");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const steps = ["Risikodaten erfassen", "Kontrolle hinzufügen"];
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const handleNext = () => {
    const newCompleted = { ...completed };
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setConfirm(false);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Typography
            sx={{ textAlign: "center", fontSize: "30px", flex: 1 }}
            id="customized-dialog-title"
          >
            {riskDetails.incidentType[0].toUpperCase() +
              riskDetails.incidentType.slice(1)}{" "}
            ins Risikomanagement aufnehmen
          </Typography>
          <Box sx={{ flex: "0 0 auto" }}>
            <Iconify
              onClick={handleClose}
              icon="mingcute:close-line"
              style={{ fontSize: "50px", color: "action.active" }}
              sx={{
                transition: "transform 0.1s",
                "&:hover": {
                  transform: "scale(1.8)",
                },
              }}
            />
          </Box>
        </Box>

        <Divider />
        <DialogContent>
          <HorizontalNonLinearStepper
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            completed={completed}
            handleNext={handleNext}
            handleBack={handleBack}
            handleReset={handleReset}
          />
          <FormRisk
            control={control}
            typeOfEntry={typeOfEntry}
            activeStep={activeStep}
            confirm={confirm}
            setConfirm={setConfirm}
            zeitraumval={zeitraumval}
          />
        </DialogContent>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogActions>
            {activeStep !== 0 && (
              <Button
                variant="contained"
                style={{ background: "#9E9E9E" }}
                onClick={handleBack}
              >
                Zurück
              </Button>
            )}
            {activeStep === steps.length - 1 ? (
              <Button
                variant="contained"
                color="secondary"
                disabled={!confirm}
                onClick={handleSubmit(onSubmit)}
              >
                Absenden
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleNext()}
              >
                Weiter
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>

      <MySlideSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={msg}
      />
    </div>
  );
}
