// ChatView.js
import React, { useEffect, useRef } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import Scrollbar from "../../../../components/scrollbar";
import {
  useCreateNewChatMutation,
  useSendMessageMutation,
} from "../../../../features/chats/chatsApiSlice";

function ChatView({
  currentChat,
  newChatUser,
  message,
  setMessage,
  currentUserId,
  onNewMessage,
  setOpenPopover,
}) {
  // Mutation hooks
  const [createNewChat, { isLoading, isError, error }] =
    useCreateNewChatMutation();
  const [sendMessage] = useSendMessageMutation();

  // Scroll to the bottom when messages change
  const scrollRef = useRef(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentChat?.messages]);

  const handleCreateNewChat = async () => {
    try {
      await createNewChat({
        userId: newChatUser?._id,
        message,
      }).unwrap();
      setMessage(""); // Clear the message after sending
      setOpenPopover(false);
    } catch (error) {
      console.error("Failed to create new chat:", error);
    }
  };

  const handleSendMessage = async () => {
    try {
      const newMessage = {
        chatId: currentChat?._id,
        text: message,
        from: currentUserId,
        timestamp: new Date(),
        read: true, // Assuming the message is read by the sender
      };

      // Optimistically update the current chat with the new message
      onNewMessage(newMessage);

      // Send the message to the server
      await sendMessage(newMessage).unwrap();
      setMessage("");
    } catch (error) {
      console.error("Failed to send message:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && message.trim()) {
      if (newChatUser) {
        handleCreateNewChat();
      } else {
        handleSendMessage();
      }
      event.preventDefault(); // Prevent the default action (form submission)
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        maxHeight: "100%", // Ensure it doesn't overflow
      }}
    >
      <Scrollbar
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          maxHeight: "400px", // Adjust this based on the desired max height
          p: 2,
        }}
      >
        {currentChat &&
          currentChat.messages.map((message, index) => (
            <Box
              key={index}
              sx={{
                textAlign: message.from === currentUserId ? "right" : "left",
                mb: 1,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  display: "inline-block",
                  px: 2,
                  py: 1,
                  borderRadius: 2,
                  maxWidth: "80%",
                  backgroundColor:
                    message.from === currentUserId
                      ? "primary.light"
                      : "grey.300",
                  color:
                    message.from === currentUserId
                      ? "primary.contrastText"
                      : "text.primary",
                }}
              >
                {message.text}
              </Typography>
            </Box>
          ))}
        {newChatUser && (
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", textAlign: "center" }}
          >
            Beginne einen neuen Chat
          </Typography>
        )}
        <div ref={scrollRef} /> {/* Reference element to scroll into view */}
      </Scrollbar>
      <Box sx={{ p: 2, borderTop: "1px solid #ddd", backgroundColor: "#fff" }}>
        <Box sx={{ display: "flex" }}>
          <TextField
            variant="outlined"
            placeholder="Nachricht eingeben"
            fullWidth
            size="small"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ ml: 1 }}
            onClick={newChatUser ? handleCreateNewChat : handleSendMessage}
            disabled={isLoading || message.trim() === ""}
          >
            Senden
          </Button>
        </Box>
        {isError && (
          <Typography variant="body2" color="error">
            Fehler beim Senden der Nachricht:{" "}
            {error?.data?.message || error.message}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default ChatView;
