import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setDialog } from "../../features/dialog/dialogSlice";
import ErrorAlert from "../alerts/ErrorAlert";
import CreateFileDataForm from "../forms/Forms/CreateFileDataForm/CreateFileDataForm";
import {
  useAddNewFileDataMutation,
  useCreateProofDocFromFormDocMutation,
} from "../../features/fileData/fileDataApiSlice";
import { useGetFileStructureQuery } from "../../features/files/filesApiSlice";
import NewFolderSelector from "../forms/Forms/CreateFileDataForm/NewFolderSelector";
import { getLast8CharsOfId } from "../../utils/fileUtils";
import TextField from "../forms/TextField";

function CreateProofDialog({ dialog, data, handleClose }) {
  const {
    type,
    data: { fileData },
  } = dialog;
  const [createProofDocFromFormDoc] = useCreateProofDocFromFormDocMutation();

  // Generate new proof file name with last 8 chars of the _id
  const proofFileName = `NW-${
    new Date().toISOString().split("T")[0]
  }-${getLast8CharsOfId(fileData._id)}-${fileData.fileName}`;

  const { handleSubmit, control, watch, setError } = useForm({
    defaultValues: {
      fileName: proofFileName,
    },
  });

  console.log("proofFileName :>> ", proofFileName);

  const { data: fileStructure } = useGetFileStructureQuery();

  const proofDocsFolder = [
    fileStructure[0]?.children?.find((o) => o.name.includes("Nachweise")),
  ];

  const revisionChecked = watch("revision");
  const selectedFolder = watch("selectedFolder");

  const [addNewFileData, { isError }] = useAddNewFileDataMutation();

  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    console.log("fileData :>> ", fileData);
    console.log("values :>> ", values);
    // try {
    //   let result = await createProofDocFromFormDoc({
    //     ...fileData,
    //     ...values,
    //   }).unwrap();
    //   if (result?.type === "success") {
    //     dispatch(
    //       setDialog({
    //         title: `Nachweis wurde erfolgreich erstellt!`,
    //         type: "SUCCESS",
    //         data: {
    //           successText: `Nachweis:\n${fileData.fileName} wurde erfolgreich erstellt!`,
    //         },
    //       })
    //     );
    //   }
    //   console.log("result :>> ", result);
    // } catch (error) {
    //   console.log("error :>> ", error);
    // }
  };

  return (
    <div>
      <Dialog open maxWidth="lg">
        <DialogTitle>{dialog?.title ?? ""}</DialogTitle>
        <DialogContent>
          <Typography>
            Über diesen Dialog können Sie aus dem freigegebenen Formblatt einen
            Nachweis erzeugen. Es wurde bereits ein Ordner vorausgewählt, Sie
            können jedoch auch selbst einen Ordner erstllen in dem der Nachweis
            anschließend abgelegt wird.
          </Typography>
          <br />
          <form className="form">
            <Grid container>
              <Grid item xs={12}>
                <Controller
                  name="selectedFolder"
                  required={false}
                  control={control}
                  render={({ field }) => (
                    <NewFolderSelector
                      folderStructure={proofDocsFolder}
                      proofFolder={fileData?.proofFolder}
                      control={control}
                      name="selectedFolder"
                      infoText={
                        "Bitte einen Ordner auswählen, in dem die zukünftigen Nachweise gespeichert werden."
                      }
                      required={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Ein Dokumentname bestehend aus NW (Nachweis), dem aktuellen
                  Datum und den letzten 8 Stellen der Dokumenten ID wurde
                  verwendet um einen Namen für den Nachweis bereits
                  vorzuschlagen. Sie können aber jeden beliebigen Namen wählen.
                </Typography>
                <br />
                <TextField
                  name="fileName"
                  label="Dokumentname"
                  required={true}
                  fullWidth
                  errorMsg="Bitte angeben"
                  control={control}
                  helperText="Bitte einen Dokumentnamen angeben."
                />
              </Grid>
            </Grid>
            {isError && (
              <div style={{ marginTop: 15 }}>
                <ErrorAlert errorMessage="Fehler beim erstellen des Dokuments" />
              </div>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            autoFocus
            onClick={handleSubmit(onSubmit)}
          >
            {"NACHWEIS ERZEUGEN"}
          </Button>
          <Button onClick={handleClose} color="primary">
            Beenden
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CreateProofDialog.propTypes = {
  dialog: PropTypes.object,
  handleClose: PropTypes.func,
};

export default CreateProofDialog;
