import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  styled,
} from "@mui/material";
import NewsForm from "./NewsForm";
import VisibilityForm from "./VisibilityForm";
import Iconify from "../../../../components/iconify";
import { FormProvider, useForm } from "react-hook-form";
import { useAddNewNewsMutation } from "../../../../features/news/newsApiSlice";
import NewsDialogStep2 from "./NewsDialogStep2";

const steps = ["Inhalt eingeben", "Bild hochladen", "Zielgruppe auswählen"];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function NewsDialog({ handleClose, newsFlag, newsDetails }) {
  const [addNewNews] = useAddNewNewsMutation();
  const [activeStep, setActiveStep] = useState(0);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [fileError, setFileError] = useState(false);

  const methods = useForm({
    defaultValues: {
      title: newsDetails?.title || "",
      subtitle: newsDetails?.subtitle || "",
      content: newsDetails?.content || "",
      visibilityScope: "office",
      selectedImageIndex: 0, // Default to Image 1 being selected
      imageDefaultPath: "1",
      imagePath: "",
      url: "",
      documentUrl: "",
      useCustomImage: false,
      linkDocument: false,
    },
  });

  const { handleSubmit, trigger, control, setValue, watch } = methods;

  const useCustomImage = watch("useCustomImage");
  const linkDocument = watch("linkDocument");

  const handleNext = async () => {
    const isValid = await trigger();
    if (isValid) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const onSubmit = async (data) => {
    try {
      const newsData = { ...data };
      await addNewNews(newsData);

      handleClose();
    } catch (error) {
      console.error("Fehler beim Erstellen der News: ", error);
    }
  };

  return (
    <FormProvider {...methods}>
      <BootstrapDialog onClose={handleClose} open>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Typography
            id="customized-dialog-title"
            sx={{ textAlign: "center", fontSize: "30px", flex: 1 }}
          >
            {newsFlag === "EDIT"
              ? "Beitrag bearbeiten"
              : "Neuen Beitrag erstellen"}
          </Typography>
          <Box sx={{ flex: "0 0 auto" }}>
            <Iconify
              onClick={handleClose}
              icon="mingcute:close-line"
              style={{ fontSize: "50px", color: "action.active" }}
              sx={{
                transition: "transform 0.1s",
                "&:hover": {
                  transform: "scale(1.8)",
                },
              }}
            />
          </Box>
        </Box>

        <DialogContent dividers>
          <Stepper activeStep={activeStep} sx={{ pb: 2 }}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {/* Step 1: Title, Subtitle, and Content */}
          {activeStep === 0 && <NewsForm />}

          {/* Step 2: Image Upload */}
          {activeStep === 1 && (
            <NewsDialogStep2
              control={control}
              setValue={setValue}
              file={file}
              setFile={setFile}
              filePreview={filePreview}
              setFilePreview={setFilePreview}
              useCustomImage={useCustomImage}
              linkDocument={linkDocument}
              fileError={fileError}
            />
          )}

          {/* Step 3: Select Audience */}
          {activeStep === 2 && <VisibilityForm control={control} />}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleBack} disabled={activeStep === 0}>
            Zurück
          </Button>
          {activeStep === steps.length - 1 ? (
            <Button variant="contained" onClick={handleSubmit(onSubmit)}>
              {newsFlag === "EDIT" ? "Aktualisieren" : "Erstellen"}
            </Button>
          ) : (
            <Button variant="contained" onClick={handleNext}>
              Weiter
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </FormProvider>
  );
}

NewsDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  newsFlag: PropTypes.string.isRequired,
  newsDetails: PropTypes.object,
};
