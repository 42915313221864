import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// @mui
import { styled, alpha } from "@mui/material/styles";
import { Box, Drawer, Typography, Stack, IconButton } from "@mui/material";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// components
import Logo from "../../../components/logo";
import Scrollbar from "../../../components/scrollbar";
import NavSection from "../../../components/nav-section";
//
// import PraxisSVG from "./pkas_logo.svg";
import PraxisSVG from "./oesterle-logo.png";
import AvatarIntern from "../../../components/avatar/AvatarIntern";
import { selectCurrentUserData } from "../../../features/auth/authSlice";
import { useSelector } from "react-redux";
import useAuth from "../../../hooks/useAuth";
import SelectCurrentOfficeMenu from "../../../components/office/SelectCurrentOfficeMenu";
import { Icon } from "@iconify/react";
import { serverURL } from "../../../app/api/axios/http.service";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme) => ({
  width: NAV_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DesktopDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: NAV_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onOpenNav, onCloseNav }) {
  const currentUserData = useSelector(selectCurrentUserData);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { status } = useAuth();
  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (openNav && !isDesktop) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleUserBoxClick = () => {
    navigate("/dashboard/profil");
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      {openNav && (
        <>
          <Box sx={{ px: 2.5, pb: 2, display: "inline-flex" }}>
            <Logo />
          </Box>

          <Box
            onClick={() => handleUserBoxClick()}
            sx={{ mb: 5, mx: 2.5, cursor: "pointer" }}
          >
            <StyledAccount>
              <AvatarIntern
                name={`${currentUserData.firstName} ${currentUserData.lastName}`}
              />

              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                  {`${currentUserData.firstName} ${currentUserData.lastName}`}
                </Typography>

                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {status}
                </Typography>
              </Box>
            </StyledAccount>
          </Box>

          <Box sx={{ mx: 2.5 }}>
            <SelectCurrentOfficeMenu />
          </Box>
        </>
      )}

      <NavSection />

      {openNav && (
        <>
          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ alignSelf: "flex-end", px: 2.5, pb: 5, mt: 0 }}>
            <Stack alignItems="center" spacing={3}>
              <Box
                component="img"
                // src={PraxisSVG}
                src={`${serverURL()}/public/tenant/image/${
                  currentUserData?.tenant ?? " "
                }`}
                alt="Praxis Logo"
                // onClick={() =>
                //   window.open("https://zahnarztpraxis-oesterle.de/", "_blank")
                // }
                // href="https://zahnarztpraxis-oesterle.de/" make this go to tenant homepage
                sx={{
                  cursor: "pointer",
                  maxHeight: 100,
                }}
              />
            </Stack>
          </Box>
        </>
      )}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        // width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <DesktopDrawer
          variant="permanent"
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          <DrawerHeader>
            {openNav ? (
              <IconButton onClick={onCloseNav}>
                {<Icon icon="material-symbols:chevron-left" />}
              </IconButton>
            ) : (
              <IconButton onClick={onOpenNav}>
                {<Icon icon="material-symbols:chevron-right" />}
              </IconButton>
            )}
          </DrawerHeader>
          {renderContent}
        </DesktopDrawer>
      ) : (
        // OLD DESKTOP DRAWER
        // <Drawer
        //   open
        //   variant="permanent"
        //   PaperProps={{
        //     sx: {
        //       width: NAV_WIDTH,
        //       bgcolor: "background.default",
        //       borderRightStyle: "dashed",
        //     },
        //   }}
        // >
        //   {renderContent}
        // </Drawer>
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
