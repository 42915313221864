import React, { useState } from "react";
import { Popover, Box, IconButton, Divider, Typography } from "@mui/material";
import Iconify from "../../../../components/iconify";
import Scrollbar from "../../../../components/scrollbar";
import ChatList from "./ChatList";
import UserList from "./UserList";
import ChatView from "./ChatView";
import { AvatarIntern } from "src/components/avatar";
import { useGetUsersCleanQuery } from "src/features/users/usersApiSlice";
import { useMarkMessagesAsReadMutation } from "../../../../features/chats/chatsApiSlice";

function Chats({
  open,
  setOpenPopover,
  anchorEl,
  onClose,
  initialChats,
  currentUserId,
}) {
  const { data: allUsers } = useGetUsersCleanQuery();
  const [markMessagesAsRead] = useMarkMessagesAsReadMutation();
  const [view, setView] = useState("chats"); // 'chats', 'users', 'chat', 'newChat'
  const [currentChat, setCurrentChat] = useState(null);
  const [newChatUser, setNewChatUser] = useState(null);
  const [chats, setChats] = useState(initialChats || []);
  const [message, setMessage] = useState("");

  // open a vailable chat
  const handleOpenChat = async (chat) => {
    console.log("chat", chat);
    setCurrentChat(chat);
    setView("chat");

    const updatedChats = chats.map((c) =>
      c.id === chat.id
        ? {
            ...c,
            messages: c.messages.map((msg) =>
              msg.read ? msg : { ...msg, read: true }
            ),
          }
        : c
    );
    setChats(updatedChats);
    handleMarkMessagesAsRead(chat?._id);
  };

  const handleMarkMessagesAsRead = async (chatId) => {
    try {
      console.log("chatId", chatId);
      await markMessagesAsRead(chatId).unwrap();
      console.log("Messages marked as read successfully.");
    } catch (error) {
      console.error("Failed to mark messages as read:", error);
    }
  };

  // go back button <-
  const handleBack = () => {
    setChats(initialChats || []);
    setView("chats");
    setCurrentChat(null);
    setNewChatUser(null);
    setMessage("");
  };

  // open a new chat, and set the value newChatUser, to have another settings for creating a new chat
  const handleStartNewChat = (user) => {
    setNewChatUser(user);
    setView("newChat");
  };

  // send a new message and automatically show the user the new message
  const handleNewMessage = (newMessage) => {
    if (view === "chat" && currentChat) {
      const updatedChats = chats.map((chat) =>
        chat.id === currentChat.id
          ? {
              ...chat,
              messages: [...chat.messages, newMessage],
            }
          : chat
      );

      setChats(updatedChats);
      setCurrentChat({
        ...currentChat,
        messages: [...currentChat.messages, newMessage],
      });
    }
  };

  // filter allUsers with the vailable chat users
  const availableUsers = allUsers?.filter(
    (user) =>
      !chats.some((chat) =>
        chat.users.some((chatUser) => chatUser._id === user._id)
      )
  );

  return (
    <Popover
      open={Boolean(open)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        sx: {
          mt: 1.5,
          ml: 0.75,
          width: 360,
          maxHeight: 650,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {(view === "chat" || view === "newChat" || view === "users") && (
              <IconButton onClick={handleBack}>
                <Iconify icon="ion:arrow-back" />
              </IconButton>
            )}
            {(view === "chat" && currentChat) ||
            (view === "newChat" && newChatUser) ? (
              <>
                <AvatarIntern
                  name={
                    view === "chat"
                      ? `${currentChat?.users[0].firstName} ${currentChat?.users[0].lastName}`
                      : `${newChatUser?.firstName} ${newChatUser?.lastName}`
                  }
                />
                <Typography variant="subtitle1" sx={{ ml: 2 }}>
                  {view === "chat"
                    ? `${currentChat?.users[0].firstName} ${currentChat?.users[0].lastName}`
                    : `${newChatUser?.firstName} ${newChatUser?.lastName}`}
                </Typography>
              </>
            ) : (
              <Typography variant="subtitle1">
                {view === "chats"
                  ? "Chats"
                  : view === "users"
                  ? "Benutzer"
                  : ""}
              </Typography>
            )}
          </Box>
          {view === "chats" && (
            <IconButton color="primary" onClick={() => setView("users")}>
              <Iconify icon="streamline:add-1-solid" />
            </IconButton>
          )}
        </Box>
      </Box>

      <Divider sx={{ borderStyle: "dashed" }} />

      <Scrollbar sx={{ flexGrow: 1 }}>
        {view === "chats" && (
          <ChatList
            chats={chats}
            handleOpenChat={handleOpenChat}
            setView={setView}
          />
        )}
        {view === "users" && (
          <UserList
            allUsers={availableUsers}
            handleStartNewChat={handleStartNewChat}
          />
        )}
        {(view === "chat" || view === "newChat") && (
          <ChatView
            currentChat={currentChat}
            newChatUser={newChatUser}
            message={message}
            setMessage={setMessage}
            currentUserId={currentUserId}
            onNewMessage={handleNewMessage}
            setOpenPopover={setOpenPopover}
          />
        )}
      </Scrollbar>
    </Popover>
  );
}

export default Chats;
