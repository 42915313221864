import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import ControlledRadioButton from "../../../../components/forms/ControlledRadioButton";

VisibilityForm.propTypes = {
  control: PropTypes.object.isRequired,
};

export default function VisibilityForm({ control }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid item xs={5.5} sx={{ ml: 2 }}>
          <ControlledRadioButton
            control={control}
            name="visibilityScope"
            label="Geltungsbereich"
            required={true}
            itemArray={[
              {
                label: "Für alle Praxen",
                value: "tenant",
              },
              {
                label: "Nur diese Praxis",
                value: "office",
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
