import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useController } from "react-hook-form";
import FileUpload from "../../../../components/FileUploadArea/FileUpload";
import TextField from "../../../../components/forms/TextField";
import ControlledCheckbox from "../../../../components/forms/ControlledCheckbox";

const defaultImages = [
  require("./1.jpg"),
  require("./2.jpg"),
  require("./3.jpg"),
];

export default function NewsDialogStep2({
  control,
  setValue,
  file,
  setFile,
  filePreview,
  setFilePreview,
  useCustomImage,
  linkDocument,
  fileError,
}) {
  const {
    field: { value: selectedImageIndex, onChange: setSelectedImageIndex },
  } = useController({
    name: "selectedImageIndex",
    control,
    defaultValue: 0, // Default to the first image
  });

  return (
    <>
      <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
        Wählen Sie ein Bild aus:
      </Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {defaultImages.map((imgSrc, index) => (
          <Grid item xs={4} key={index}>
            <Box
              component="img"
              src={imgSrc}
              alt={`Default ${index + 1}`}
              onClick={() => {
                setSelectedImageIndex(index); // Update the selected image index
                setValue("imageDefaultPath", `${index + 1}`); // Update imageDefaultPath in form state
                setValue("imagePath", ""); // Clear custom image path if default image is selected
                setValue("useCustomImage", false); // Deselect custom image upload if a default image is clicked
              }}
              sx={{
                width: "100%",
                height: "auto",
                border:
                  selectedImageIndex === index
                    ? "3px solid blue"
                    : "2px solid #006666",
                borderRadius: "5px",
                cursor: "pointer",
                transition: "border 0.3s",
              }}
            />
          </Grid>
        ))}
      </Grid>

      <ControlledCheckbox
        control={control}
        label="Eigenes Bild hochladen"
        name="useCustomImage"
        disabled
        onChange={(e) => {
          setValue("useCustomImage", e.target.checked);
          if (e.target.checked) {
            setSelectedImageIndex(null); // Clear the selected image index when choosing a custom image
            setValue("imageDefaultPath", ""); // Clear imageDefaultPath when using a custom image
          } else {
            setValue("imagePath", ""); // Ensure imagePath is cleared if toggling off custom image
          }
        }}
      />

      {useCustomImage && (
        <FileUpload
          file={file}
          setFile={setFile}
          filePreview={filePreview}
          setFilePreview={setFilePreview}
          onUpload={setFile}
          uploadLabel="Bild hochladen"
          previewTitle="Bild-Vorschau:"
          dropzoneText="Hier klicken oder Bild hierherziehen"
        />
      )}

      {fileError && (
        <Typography color="error" variant="body2">
          Bitte laden Sie ein Bild hoch, um fortzufahren.
        </Typography>
      )}

      <ControlledCheckbox
        control={control}
        label="Ein Dokument verlinken"
        name="linkDocument"
        onChange={(e) => setValue("linkDocument", e.target.checked)}
        disabled
      />

      {linkDocument && (
        <TextField
          control={control}
          name="documentUrl"
          label="Dokument-Url"
          fullWidth
        />
      )}
    </>
  );
}
